<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { hotelStarRating, hotelType } from '@/utils/textFile'

export default {
  name: 'hotelHomestayManage',
  data() {
    return {
      ...api.command.getState(),
      star_hotel_arr: [],
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/hotel/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '酒店名称',
          key: 'name',
          type: 'input',
        },
        {
          name: 'ID',
          key: 'id',
          type: 'input',
        },
        {
          name: '星级',
          key: 'starLevel',
          type: 'select',
          typeData: hotelStarRating(),
        },
        {
          name: '类型',
          key: 'hotelType',
          type: 'select',
          typeData: hotelType(),
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '酒店名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.bannerUrl.split(',')[0]} />
                <div>{text.length > 45 ? text.substring(0, 45) + '...' : text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
          dataIndex: 'linkShopName',
          title: '店铺名称',
          align: 'left',
        },
        {
          dataIndex: 'hotelTypeName',
          title: '类型',
          align: 'left',
        },
        {
          dataIndex: 'starLevelName',
          title: '星级',
          align: 'left',
        },
        {
          dataIndex: 'provinceName',
          title: '省市区',
          align: 'left',
          customRender: (text, records) => {
            return `${text}/${records.cityName}/${records.areaName}`
          },
        },
        {
          dataIndex: 'dataSource',
          title: '数据来源',
          align: 'left',
          filters: [
            {
              text: '平台添加',
              value: '0',
            },
            {
              text: '商家添加',
              value: '1',
            },
            {
              text: '自我游同步',
              value: '2',
            },
          ],
          onExport: (records) => {
            return ['平台添加', '商家添加', '自我游同步'][records]
          },
          filterMultiple: false,
          onFilter: (value, record) => record.dataSource == value,
          customRender: (text, records) => {
            return ['平台添加', '商家添加', '自我游同步'][text]
          },
        },
        {
          dataIndex: 'roomNum',
          title: '房型数量',
          align: 'left',
          sorter: (a, b) => a.roomNum.length - b.roomNum.length,
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => record.upDown == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          width: '20%',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '转平台',
                type: 'pop',
                popTitle: '确认转平台吗?',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/hotel/updateDateSource?id=${records.id}`,
                  })
                },
                display: records.dataSource == '1',
              },
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/hotel/${records.upDown == 0 ? 'downBatch' : 'upBatch'}`,
                    params: { idList: [records.id] },
                  })
                },
                display: true,
              },
              // {
              //   name: '房型管理',
              //   onClick: () => this.$router.push(`/commodityManagement/roomManage?id=${records.id}`),
              //   display: true,
              // },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/commodityManagement/hotelHomestayManageDetail?id=${records.id}`),
                display: true,
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPut.call(this, {
                    url: `/hotel/deleteBatch`,
                    params: { idList: [records.id] },
                  })
                },
                display: records.upDown == 1,
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/hotelHomestayManageDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/hotel/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/hotel/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/hotel/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
